<template>
  <div class="sg-web-content-2">
    <img
      src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png"
      class="sg-w100"
    />
    <el-row class="sg-border-bottom">
      <el-col :sm="{ span: 16, offset: 4 }">
        <div class="sg-flex-center">
          <div
            class="sg-space-between sg-bold sg-gray-b1 sg-font-14px"
            style="width: 1152px"
          >
            <div class="sg-flex-center sg-gray-b1">
              <i class="el-icon-s-home sg-orange"></i>
              <i class="el-icon-caret-right" style="padding: 0 11px"></i>
              <div class="sg-gray-b1">关于正启之星</div>
              <i class="el-icon-caret-right" style="padding: 0 11px"></i>
              <div>产业板块</div>
            </div>
            <div class="sg-flex-center">
              <div
                :class="{
                  'sg-tab sg-pointer sg-gray-6a sg-nav-ani': true,
                  'sg-selected': tabIndex == index,
                }"
                :style="{
                  'margin-left': '30px',
                  'line-height': scrollNavDown ? '80px' : '110px',
                  height: scrollNavDown ? '82px' : '112px',
                }"
                v-for="(item, index) in tabs"
                :key="index"
                @click="goTab(item.key)"
              >
                {{ item.zh }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div>
      <el-row>
        <el-col :sm="{ span: 16, offset: 4 }">
          <div class="sg-flex-center">
            <div
              class="sg-pad-right-md sg-gray-cbc4c0"
              style="
                padding: 50px 0 38px 50px;
                width: 1152px;
                box-sizing: border-box;
              "
            >
              <div class="sg-font-16px">{{ tab.zh }}</div>
              <div class="sg-font-18px" style="padding-top: 8px">
                {{ tab.en }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="sg-core-bg"
        style="z-index: 1003; margin-bottom: 68px"
        id="sg-core-box"
      >
        <el-col
          :sm="{ span: 24 }"
          style="padding-top: calc((100vh - 499px) / 2)"
        >
          <div class="sg-flex-center">
            <div
              @mousewheel.stop.prevent="mouseScroll"
              class="sg-relative"
              style="width: 1016px"
            >
              <el-carousel
                height="499px"
                :autoplay="false"
                :arrow="'never'"
                @change="caChange"
                ref="myCarousel"
                indicator-position="none"
                v-show="caShow"
                id="sg-industry-box"
                class="sg-white-bg"
              >
                <el-carousel-item v-for="(item, index) in tabs" :key="index">
                  <div class="sg-flex sg-industry-box" style="height: 499px">
                    <div
                      class="sg-white-bg sg-flex-grow"
                      :style="{
                        height: '499px',
                        width: '679px',
                        'box-sizing': 'border-box',
                        padding: item.pad,
                      }"
                    >
                      <transition name="sg-fade-up-0p2">
                        <h3
                          class="sg-blue-1340b5 sg-font-19px sg-font-hum"
                          :style="{ color: item.enColor }"
                          v-show="tabIndex == index"
                        >
                          {{ item.en }}
                        </h3>
                      </transition>
                      <transition name="sg-fade-up-0p4">
                        <div
                          class="sg-index-line-blue"
                          :style="{
                            margin: '16px 0 20px 0',
                            'background-color': item.lineColor,
                          }"
                          v-show="tabIndex == index"
                        ></div>
                      </transition>
                      <transition name="sg-fade-up-0p6">
                        <div
                          class="sg-gray-31 sg-light"
                          style="font-size: 38.9px; padding-bottom: 17px"
                          v-show="tabIndex == index"
                          :style="{ color: item.zhColor }"
                        >
                          {{ item.zh }}
                        </div>
                      </transition>
                      <transition name="sg-fade-in-0p8">
                        <div
                          class="sg-gray-85 sg-font-12px sg-line-height-18"
                          :style="{
                            'line-height': index == 4 ? '20px' : '22px',
                          }"
                          v-show="tabIndex == index"
                        >
                          <div v-html="item.txt"></div>
                        </div>
                      </transition>
                    </div>
                    <transition name="sg-fade-out-1">
                      <img
                        class="sg-img-right"
                        :src="item.img"
                        style="width: 337px; height: 499px"
                        v-show="tabIndex == index"
                      />
                    </transition>
                  </div>
                </el-carousel-item>
              </el-carousel>

              <transition name="sg-fade-left-0p2">
                <div class="sg-pox-btn" v-show="caShow" style="top: 50%">
                  <div
                    :class="{ 'sg-pox-prev': true, on: arrow == 'right' }"
                    @mouseenter="arrow = 'right'"
                    @mouseleave="arrow = 'left'"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide"
                    @click="prevCard"
                  ></div>
                  <div
                    @mouseenter="arrow = 'left'"
                    :class="{ 'sg-pox-next': true, on: arrow == 'left' }"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide"
                    @click="nextCard"
                  ></div>
                </div>
              </transition>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Tempalte",
  props: {},
  data: () => {
    return {
      arrow: "left",
      tabIndex: -1,
      caShow: false,
      isMove: false,
      tabs: [
        {
          zh: "财税服务",
          key: "finance",
          en: "FISCAL AND TAX SERVICES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry/finance-2.png",
          pad: "101px 50px 10px 53px",
          enColor: "#1340B5",
          lineColor: "#0265A1",
          zhColor: "#313131",
          txt: "正启之星为企业及个人提供专业财税服务，包括财务咨询、税务咨询、注册代理、会计服务、上市指导、融通资金、股权架构与激励、减持退出等。一站式、管家式综合财税服务，覆盖企业从注册、成长、发展到飞跃全过程，帮助企业发展更快、更好、更省时、更省力。<br>从业10年以上、服务过多家跨国公司及上市公司的资深税务师、注册会计师专家团队，精谙全国各地不同税收政策，结合每一位客户具体需求，量身打造合规、优质财税咨询与规划服务，为企业发展每一步保驾护航。<br>目前，企业服务客户涵盖科技、影视、医药、金融、文化、贸易等三十余个行业。<br>旗下“税之蛙”服务品牌，利用园区优质资源，专注为企业和个人提供综合财税规划服务，目前注册企业两万多家。",
          padTop: "102px",
        },
        {
          zh: "医疗服务",
          key: "medical",
          en: "MEDICAL SERVICES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry/medical-2.png",
          pad: "107px 62px 10px 53px",
          enColor: "#7D8382",
          lineColor: "#C31A1F",
          zhColor: "#007DFF",
          txt: "正启之星旗下“安之慧SAAS平台”，是国内生命健康领域领先众包服务平台。依托高等院校、科研机构、认证机构及相关专家学者资源，为客户专业提供医疗科研技术、CRO转包、医药与器械公司推广等服务，并形成“安之慧”综合服务品牌。<br>通过服务数字化、金融支付、实名认证等一系列技术操作，我们为生命健康领域各类企业、经销商、从业者在该平台完成服务转售、交付、审核、支付等一系列服务交易事项，帮助企业在合规前提下降低成本，帮助从业者提升专业服务效率，协助政府监管部门在产业数字化新形势下优化对行业服务、支付的监管力度。<br>安之慧医疗服务团队拥有丰富的专业法规经验和完备的服务流程体系，致力为客户提供专业、全面的一站式医疗服务。",
        },
        {
          zh: "建筑服务",
          key: "building",
          en: "BUILDING SERVICE",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry/building-2.png",
          pad: "59px 62px 10px 53px",
          enColor: "#1340B5",
          lineColor: "#0265A1",
          zhColor: "#313131",
          txt: "正启之星在原有自研建筑行业三大平台——劳务用工SaaS平台、机械设备租赁平台、砂石料电子商务交易平台基础上，进行技术整合和服务优化，打造“程宝建服”核心品牌，推出“建筑行业众包系统”，为行业提供劳务、机械租赁、原材料交易等一站式服务及专业财税解决方案。<br>就当前建筑劳务行业存在的用工弊端和财税风险，该系统提供实名认证，实现了建筑工人身份、文化程度、工种、技能培训、从业经历、工资支付等信息的“一卡通”及电子合同服务。建筑企业在系统发布劳务合作项目，平台指派建筑工人施工。项目验收后平台发起结算，企业线上、线下支付后平台为企业开票，为个人完税。通过平台线上、线下全过程监管，可保证项目真实性及农名工工资发放的及时性。<br>就建筑企业面临的高成本设备采购压力及设备承租相关财税合规问题，企业在该系统可发布机械设备租赁合作任务，机械设备个体户通过平台接单，平台通过线上、线下全过程监管项目真实性，业务合理性，留存租赁合作证据。<br>针对原材料价格无序上涨，多现金交易，发票不合规等问题，该系统可记录真实交易信息，解决材料采购时支付、开票等难题，帮助建安企业解决进项抵扣和成本认证问题，最大程度避免行业上下游企业不规范取得发票等问题。",
        },
        {
          zh: "人力资源",
          key: "human",
          en: "HUMAN RESOURCES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry/human-2.png",
          pad: "62px 62px 10px 53px",
          enColor: "#1340B5",
          lineColor: "#0265A1",
          zhColor: "#313131",
          txt: "正启之星依托强大人力资源和专业团队丰富经验，以及众蝌云服务平台，为客户提供高水准人力资源解决方案及配套服务。<br><b>人事代理服务：</b>一站式人事解决方案，涵盖员工社保代缴、公积金代缴，薪酬管理、入离职管理、福利管理等综合服务，帮助企业简化人事工作流程，降低人力资源管理成本，提高管理效率。<br><b>人力资源外包服务：</b>专业人力资源外包服务，帮助客户简化用工程序，减少人员管理成本，降低用工风险，让客户从繁琐事务性工作中解脱出来，将精力投入到更有价值的核心业务中去。<br><b>劳务派遣服务：</b>正启之星劳务派遣服务，涵盖派遣员工劳动关系管理、薪税管理、社保管理、档案保管、员工援助及劳务纠纷处理等全方位服务，帮助企业减少劳动争议，转嫁用人风险，自主灵活用工，更好满足、促进企业发展。<br><b>灵活用工服务：</b>针对企业多元化用工需求，以及用工中存在的社保成本压力大，劳务用工无发票，临时、短期用工合规难的问题，正启之星打造“零工经济”形势下多元化用工模式服务平台“众蝌云”，为企业提供灵活用工服务。",
        },
        {
          zh: "职业培训",
          key: "education",
          en: "VOCATIONAL TRAINING",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry/education-2.png",
          pad: "40px 62px 10px 53px",
          enColor: "#C31A1F",
          lineColor: "#C31A1F",
          zhColor: "#313131",
          txt: "在国家大力推进产城融合的大背景下，在数字经济快速发展并出现很多崭新岗位与人才缺口的社会现实下，以及针对当前学校教育与就业市场高度不对称、人才结构失衡、人岗错配、高失业率等社会痛点、难点，正启之星积极响应国家号召，打造中教产教融合就业实训基地，深耕数字经济人才培养和在职学历提升，推出网络安全运维、云计算、渗透测试等专业课程，并提供从报名、试学、实训到就业落实一条龙服务，从而不断优化社会人才供需结构，满足企业人才需求，推动地方产业升级，进而为国家长远发展储备优秀专业人才。<br>尤其就毕业 0-5 年群体中的“错配人才”，我们通过精英化、多层次、高区分的课程培训体系及充分的就业辅导，利用基地优质就业资源为“人才”找准“位置”，最终实现提升人才附加值、服务社会人力资源优化配置的目标。<br>目前，基地作为传统学校教育和就业市场之间的桥梁，已持续为华为、阿里、百度云、360安全、绿盟、奇安信、金山云、电信云、中国移动、光大银行、建设银行等100+家企业输送数千名网络安全技术人才，成为信息安全职业教育领域的佼佼者。<br>此外，为贯彻落实党中央、国务院关于实现巩固拓展脱贫攻坚成果同乡村振兴有效衔接的决策部署，以及商务部等14部门研究制定的《家政兴农行动计划（2021-2025年）》，正启之星亦积极开展乡村振兴及家政兴农人才培养工作。培养方向有互联网营销，全媒体运营，人力资源，中西式烹调、面点，健康管理师，化妆师，心理咨询师，保健按摩师，整理收纳师，电焊工，汽车维修等。",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      scrollNavDown: (state) => state.scrollNavDown,
      scrollY: (state) => state.scrollY,
    }),
    tab() {
      if (this.tabIndex < 0) return {};
      return this.tabs[this.tabIndex];
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        this.goTab(toParams.tab);
      }
    );
  },
  mounted() {
    // console.log(key)
    let key = this.$route.params.tab ? this.$route.params.tab : "finance";
    // this.caShow = true

    let that = this;
    setTimeout(function () {
      that.caShow = true;
      that.isMove = false;
      that.goCore();
      that.goTab(key);
    }, 500);
  },
  methods: {
    goCore() {
      // let coreBox = document.getElementById('sg-core-box')

      // document.getElementById("sg-core-box").scrollIntoView(true);
      // window.scrollTo(0, document.getElementById('sg-core-box').scrollTop)
      // console.log('---')
      // let that = this
      this.$nextTick(() => {
        let width = window.innerWidth;
        let scrollY = 0;
        if (width < 1440) {
          scrollY = (1440 - width) / 2;
        }
        window.scrollTo(
          scrollY,
          document.getElementById("sg-core-box").offsetTop
        );
      });
    },
    mouseScroll(e) {
      if (this.isMove) return false;
      let th = 100;
      if (e.deltaY > th) {
        this.nextCard();
      } else if (e.deltaY < 0 - th) {
        this.prevCard();
      }
      this.isMove = true;
      let that = this;
      setTimeout(function () {
        that.isMove = false;
      }, 1500);
    },
    prevCard() {
      this.$refs.myCarousel.prev();
    },
    nextCard() {
      this.$refs.myCarousel.next();
    },
    caChange(index) {
      this.tabIndex = index;
    },
    goTab(key) {
      let index = 0;
      for (let i = 0; i < this.tabs.length; ++i) {
        if (this.tabs[i].key == key) {
          index = i;
          break;
        }
      }
      this.tabIndex = index;
      this.$refs.myCarousel.setActiveItem(index);
    },
    imgChange(index) {
      this.core_index = index;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-tab.sg-selected {
  color: $red;
  border-bottom: 2px solid $red;
}
.sg-index-line {
  width: 30px;
  height: 3px;
  background: $red;
}
.sg-index-line-blue {
  width: 26px;
  height: 3px;
  background: #0265a1;
}
.sg-red-border-right {
  border-right: 2px solid $red;
}
.sg-desc {
  color: gray;
  line-height: 1.6em;
}
.sg-dash-border {
  border-top: 1px dashed gray;
}
.sg-hover-gray:hover {
  background: $gray-4;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/core-bg.png");
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.sg-core-txt-box {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40%;
}
.sg-img-right {
  width: 300px;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/industry-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
}
</style>
