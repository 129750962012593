var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sg-web-content-2"},[_c('img',{staticClass:"sg-w100",attrs:{"src":"https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png"}}),_c('el-row',{staticClass:"sg-border-bottom"},[_c('el-col',{attrs:{"sm":{ span: 16, offset: 4 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticClass:"sg-space-between sg-bold sg-gray-b1 sg-font-14px",staticStyle:{"width":"1152px"}},[_c('div',{staticClass:"sg-flex-center sg-gray-b1"},[_c('i',{staticClass:"el-icon-s-home sg-orange"}),_c('i',{staticClass:"el-icon-caret-right",staticStyle:{"padding":"0 11px"}}),_c('div',{staticClass:"sg-gray-b1"},[_vm._v("关于正启之星")]),_c('i',{staticClass:"el-icon-caret-right",staticStyle:{"padding":"0 11px"}}),_c('div',[_vm._v("产业板块")])]),_c('div',{staticClass:"sg-flex-center"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:{
                'sg-tab sg-pointer sg-gray-6a sg-nav-ani': true,
                'sg-selected': _vm.tabIndex == index,
              },style:({
                'margin-left': '30px',
                'line-height': _vm.scrollNavDown ? '80px' : '110px',
                height: _vm.scrollNavDown ? '82px' : '112px',
              }),on:{"click":function($event){return _vm.goTab(item.key)}}},[_vm._v(" "+_vm._s(item.zh)+" ")])}),0)])])])],1),_c('div',[_c('el-row',[_c('el-col',{attrs:{"sm":{ span: 16, offset: 4 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticClass:"sg-pad-right-md sg-gray-cbc4c0",staticStyle:{"padding":"50px 0 38px 50px","width":"1152px","box-sizing":"border-box"}},[_c('div',{staticClass:"sg-font-16px"},[_vm._v(_vm._s(_vm.tab.zh))]),_c('div',{staticClass:"sg-font-18px",staticStyle:{"padding-top":"8px"}},[_vm._v(" "+_vm._s(_vm.tab.en)+" ")])])])])],1),_c('el-row',{staticClass:"sg-core-bg",staticStyle:{"z-index":"1003","margin-bottom":"68px"},attrs:{"id":"sg-core-box"}},[_c('el-col',{staticStyle:{"padding-top":"calc((100vh - 499px) / 2)"},attrs:{"sm":{ span: 24 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticClass:"sg-relative",staticStyle:{"width":"1016px"},on:{"mousewheel":function($event){$event.stopPropagation();$event.preventDefault();return _vm.mouseScroll.apply(null, arguments)}}},[_c('el-carousel',{directives:[{name:"show",rawName:"v-show",value:(_vm.caShow),expression:"caShow"}],ref:"myCarousel",staticClass:"sg-white-bg",attrs:{"height":"499px","autoplay":false,"arrow":'never',"indicator-position":"none","id":"sg-industry-box"},on:{"change":_vm.caChange}},_vm._l((_vm.tabs),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"sg-flex sg-industry-box",staticStyle:{"height":"499px"}},[_c('div',{staticClass:"sg-white-bg sg-flex-grow",style:({
                      height: '499px',
                      width: '679px',
                      'box-sizing': 'border-box',
                      padding: item.pad,
                    })},[_c('transition',{attrs:{"name":"sg-fade-up-0p2"}},[_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == index),expression:"tabIndex == index"}],staticClass:"sg-blue-1340b5 sg-font-19px sg-font-hum",style:({ color: item.enColor })},[_vm._v(" "+_vm._s(item.en)+" ")])]),_c('transition',{attrs:{"name":"sg-fade-up-0p4"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == index),expression:"tabIndex == index"}],staticClass:"sg-index-line-blue",style:({
                          margin: '16px 0 20px 0',
                          'background-color': item.lineColor,
                        })})]),_c('transition',{attrs:{"name":"sg-fade-up-0p6"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == index),expression:"tabIndex == index"}],staticClass:"sg-gray-31 sg-light",staticStyle:{"font-size":"38.9px","padding-bottom":"17px"},style:({ color: item.zhColor })},[_vm._v(" "+_vm._s(item.zh)+" ")])]),_c('transition',{attrs:{"name":"sg-fade-in-0p8"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == index),expression:"tabIndex == index"}],staticClass:"sg-gray-85 sg-font-12px sg-line-height-18",style:({
                          'line-height': index == 4 ? '20px' : '22px',
                        })},[_c('div',{domProps:{"innerHTML":_vm._s(item.txt)}})])])],1),_c('transition',{attrs:{"name":"sg-fade-out-1"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == index),expression:"tabIndex == index"}],staticClass:"sg-img-right",staticStyle:{"width":"337px","height":"499px"},attrs:{"src":item.img}})])],1)])}),1),_c('transition',{attrs:{"name":"sg-fade-left-0p2"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.caShow),expression:"caShow"}],staticClass:"sg-pox-btn",staticStyle:{"top":"50%"}},[_c('div',{class:{ 'sg-pox-prev': true, on: _vm.arrow == 'right' },attrs:{"tabindex":"0","role":"button","aria-label":"Previous slide"},on:{"mouseenter":function($event){_vm.arrow = 'right'},"mouseleave":function($event){_vm.arrow = 'left'},"click":_vm.prevCard}}),_c('div',{class:{ 'sg-pox-next': true, on: _vm.arrow == 'left' },attrs:{"tabindex":"0","role":"button","aria-label":"Next slide"},on:{"mouseenter":function($event){_vm.arrow = 'left'},"click":_vm.nextCard}})])])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }